import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import { v4 as uuidv4 } from "uuid";
import "../../App.css";
import { projects } from "../../data";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiCornerUpLeft } from "react-icons/fi";
import { FiCornerRightUp } from "react-icons/fi";
import { CgMenuGridR } from "react-icons/cg";

const Skew = () => {
  let images = projects[3].images;
  const length = images.length;

  const [overlay, setOverlay] = useState("overlay__off");
  const [view, setView] = useState(true);
  const [slider, setSlider] = useState(true);
  const [current, setCurrent] = useState(0);

  const switchView = () => {
    setView(!view);
    setSlider(!slider);
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  if (length <= 0) {
    return null;
  }

  return (
    <div>
      <div className="back-btn">
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <FiCornerUpLeft
            style={{
              color: "#4a865f",
              width: "20px",
              height: "auto",
              marginTop: "4px",
            }}
          />
        </Link>
      </div>
      <div className="WorkApp">
        <div
          className={overlay}
          onClick={() => {
            setOverlay("overlay__off");
            document.documentElement.style.overflow = "auto";
            document.body.scroll = "yes";
          }}
        >
          <p className="overlay__text">
            <span className="overlay__text--italic">skew-whiff</span> presents
            the familiar and semmingly banal place that many call home:
            Suburbia. The long form documentary project attempts to reveal the
            often seen, yet occasionally overlooked, suburban landscape;
            endeavouring to twist the narrative of the environment’s universally
            perceived mundaneness or banality to, instead, a place full of
            beautiful and humorous fleeting moments.
          </p>
          <p className="overlay__text">
            Driven by an intuitive process through walking around, observing and
            capturing the residential areas near where I have always lived,{" "}
            <span className="overlay__text--italic">skew-whiff</span>{" "}
            investigates the chance encounters in everyday environments that so
            many come across, yet pass by, without a second glance. Primarily
            photo-based, the project also utilises ephemera, including found and
            collected objects, to reflect upon the ways in which the general
            public has misunderstood the suburban landscape, and to examine my
            own fond experience of growing up and living in the northern suburbs
            of Melbourne. I invite viewers to spend time engaging with my
            insightful observations of a seemingly prosaic, yet quirky,
            colourful and a surprisingly, rich world.
          </p>
          <p className="overlay__text">
            This work was made on the traditional lands of the Wurundjeri people
            of the Kulin nation. I pay my respects to Elders both past, present
            and emerging.
          </p>
        </div>
        {view ? (
          <div className="Work__container--carousel">
            {images.map((img, index) => (
              <div
                key={uuidv4()}
                className={index === current ? "slide active" : "slide"}
              >
                {index === current && (
                  <Image
                    alt="Thumbnail"
                    className="Work__image--carousel"
                    src={img}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="Work__container--thumbnail">
            {images.map((img, index) => (
              <LazyLoad
                height={200}
                debounce={false}
                throttle={200}
                offsetVertical={200}
                key={uuidv4()}
              >
                <div
                  className={index === current ? "thumb active" : "thumb"}
                  onClick={() => {
                    setCurrent(index);
                    switchView();
                  }}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifySelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    alt="Thumbnail"
                    className="Work__image--thumbnail content-image"
                    src={img}
                  />
                </div>
              </LazyLoad>
            ))}
          </div>
        )}
      </div>
      <div className="Work__footer--carousel">
        <ul className="Work__info">
          <li className="Work__item btn-info">
            <button
              className="overlay__btn"
              onClick={() => {
                setOverlay("overlay__on");
                document.documentElement.style.overflow = "hidden";
                document.body.scroll = "no";
              }}
            >
              info
            </button>
          </li>
        </ul>
        {slider ? (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-left"
              onClick={prevSlide}
            >
              <FiChevronLeft
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
            <li className="Work__item Work__item--num">
              {current + 1} / {length}
            </li>
            <li className="Work__item Work__item--toggle" onClick={nextSlide}>
              <FiChevronRight
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        ) : (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-up"
              onClick={scrollToTop}
            >
              <FiCornerRightUp
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        )}
        <ul className="Work__view">
          <li className="Work__item btn-view">
            <CgMenuGridR
              onClick={switchView}
              style={{
                color: "#4a865f",
                width: "24px",
                height: "auto",
                marginTop: "4px",
                marginLeft: "20px",
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Skew;
