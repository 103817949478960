import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <nav className="footer">
      <ul className="footer-list">
        <li className="footer-list__item">© Ashley Kelam 2023</li>
      </ul>
    </nav>
  );
};

export default Footer;
