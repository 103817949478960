import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import { v4 as uuidv4 } from "uuid";
import "../../App.css";
import { projects } from "../../data";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiCornerUpLeft } from "react-icons/fi";
import { FiCornerRightUp } from "react-icons/fi";
import { CgMenuGridR } from "react-icons/cg";

const Moments = () => {
  let images = projects[2].images;
  const length = images.length;

  const [overlay, setOverlay] = useState("overlay__off");
  const [view, setView] = useState(true);
  const [slider, setSlider] = useState(true);
  const [current, setCurrent] = useState(0);

  const switchView = () => {
    setView(!view);
    setSlider(!slider);
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  if (length <= 0) {
    return null;
  }

  return (
    <div>
      <div className="back-btn">
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <FiCornerUpLeft
            style={{
              color: "#4a865f",
              width: "20px",
              height: "auto",
              marginTop: "4px",
            }}
          />
        </Link>
      </div>
      <div className="WorkApp">
        <div
          className={overlay}
          onClick={() => {
            setOverlay("overlay__off");
            document.documentElement.style.overflow = "auto";
            document.body.scroll = "yes";
          }}
        >
          <p className="overlay__text">
            <span className="overlay__text--italic">Moments from Suburbia</span>
            ; the sound and sight of freshly mowed lawns, dogs barking through
            front windows, poor front yard designs, basketballs dribbling and
            echoing across backyards, an array of architectural and stylistic
            choices of homes, children’s chalk smearing along the footpath,
            cat’s collars jingling as they stalk the surrounding streets. I
            encountered each of these descriptions whilst regularly walking
            around, observing and capturing the residential area of where I
            live, Bundoora, 3083, throughout the months of April to October
            2020.
          </p>
          <p className="overlay__text">
            I have resided in this suburb for the entirety of my life, yet prior
            to this year, never truly liked the area where I live. Yet, as a way
            of dealing with the enforced lockdown restrictions and social
            isolation, I adopted a daily routine of going on a walk, every day
            at 5:00PM, with my two older brothers and our dog. With my camera in
            tow, I began to pause and discover all that was around me,
            intricacies and idiosyncrasies that I previously overlooked or
            failed to recognise, resulting in me falling a little in love with
            my home more and more each day. The images presented are very much a
            love letter to not only the suburb and how appreciative I am of the
            landscape, but to the people inhabiting it, my family, also.
          </p>
          <p className="overlay__text">
            This photobook aims to communicate the familiar, charming, warm and
            humorous nature found within my neighbourhood, particularly the
            quirky yet beautiful sightings and daily occurrences prevalent
            within my domestic and suburban environment.
          </p>
        </div>
        {view ? (
          <div className="Work__container--carousel">
            {images.map((img, index) => (
              <div
                key={uuidv4()}
                className={index === current ? "slide active" : "slide"}
              >
                {index === current && (
                  <Image
                    alt="Thumbnail"
                    className="Work__image--carousel"
                    src={img}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="Work__container--thumbnail">
            {images.map((img, index) => (
              <LazyLoad
                height={200}
                debounce={false}
                throttle={200}
                offsetVertical={200}
                key={uuidv4()}
              >
                <div
                  className={index === current ? "thumb active" : "thumb"}
                  onClick={() => {
                    setCurrent(index);
                    switchView();
                  }}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifySelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    alt="Thumbnail"
                    className="Work__image--thumbnail content-image"
                    src={img}
                  />
                </div>
              </LazyLoad>
            ))}
          </div>
        )}
      </div>
      <div className="Work__footer--carousel">
        <ul className="Work__info">
          <li className="Work__item btn-info">
            <button
              className="overlay__btn"
              onClick={() => {
                setOverlay("overlay__on");
                document.documentElement.style.overflow = "hidden";
                document.body.scroll = "no";
              }}
            >
              info
            </button>
          </li>
        </ul>
        {slider ? (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-left"
              onClick={prevSlide}
            >
              <FiChevronLeft
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
            <li className="Work__item Work__item--num">
              {current + 1} / {length}
            </li>
            <li className="Work__item Work__item--toggle" onClick={nextSlide}>
              <FiChevronRight
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        ) : (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-up"
              onClick={scrollToTop}
            >
              <FiCornerRightUp
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        )}
        <ul className="Work__view">
          <li className="Work__item btn-view">
            <CgMenuGridR
              onClick={switchView}
              style={{
                color: "#4a865f",
                width: "24px",
                height: "auto",
                marginTop: "4px",
                marginLeft: "20px",
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Moments;
