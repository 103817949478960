import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import { v4 as uuidv4 } from "uuid";
import "../../App.css";
import { projects } from "../../data";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiCornerUpLeft } from "react-icons/fi";
import { FiCornerRightUp } from "react-icons/fi";
import { CgMenuGridR } from "react-icons/cg";

const Toot = () => {
  let images = projects[1].images;
  const length = images.length;

  const [overlay, setOverlay] = useState("overlay__off");
  const [view, setView] = useState(true);
  const [slider, setSlider] = useState(true);
  const [current, setCurrent] = useState(0);

  const switchView = () => {
    setView(!view);
    setSlider(!slider);
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  if (length <= 0) {
    return null;
  }

  return (
    <div>
      <div className="back-btn">
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <FiCornerUpLeft
            style={{
              color: "#4a865f",
              width: "20px",
              height: "auto",
              marginTop: "4px",
            }}
          />
        </Link>
      </div>
      <div className="WorkApp">
        <div
          className={overlay}
          onClick={() => {
            setOverlay("overlay__off");
            document.documentElement.style.overflow = "auto";
            document.body.scroll = "yes";
          }}
        >
          <p className="overlay__text">
            <span className="overlay__text--italic">
              Tootgarook: A Place to See
            </span>{" "}
            is an electronic publication/photobook that shows viewers suggested
            destinations to visit and explore whilst holidaying in Tootgarook;
            recommended from my own personal experience of staying in the suburb
            in February, 2020.
          </p>
        </div>
        {view ? (
          <div className="Work__container--carousel">
            {images.map((img, index) => (
              <div
                key={uuidv4()}
                className={index === current ? "slide active" : "slide"}
              >
                {index === current && (
                  <Image
                    alt="Thumbnail"
                    className="Work__image--carousel"
                    src={img}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="Work__container--thumbnail">
            {images.map((img, index) => (
              <LazyLoad
                height={200}
                debounce={false}
                throttle={200}
                offsetVertical={200}
                key={uuidv4()}
              >
                <div
                  className={index === current ? "thumb active" : "thumb"}
                  onClick={() => {
                    setCurrent(index);
                    switchView();
                  }}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifySelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    alt="Thumbnail"
                    className="Work__image--thumbnail content-image"
                    src={img}
                  />
                </div>
              </LazyLoad>
            ))}
          </div>
        )}
      </div>
      <div className="Work__footer--carousel">
        <ul className="Work__info">
          <li className="Work__item btn-info">
            <button
              className="overlay__btn"
              onClick={() => {
                setOverlay("overlay__sml");
                document.documentElement.style.overflow = "hidden";
                document.body.scroll = "no";
              }}
            >
              info
            </button>
          </li>
        </ul>
        {slider ? (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-left"
              onClick={prevSlide}
            >
              <FiChevronLeft
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
            <li className="Work__item Work__item--num">
              {current + 1} / {length}
            </li>
            <li className="Work__item Work__item--toggle" onClick={nextSlide}>
              <FiChevronRight
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        ) : (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-up"
              onClick={scrollToTop}
            >
              <FiCornerRightUp
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        )}
        <ul className="Work__view">
          <li className="Work__item btn-view">
            <CgMenuGridR
              onClick={switchView}
              style={{
                color: "#4a865f",
                width: "24px",
                height: "auto",
                marginTop: "4px",
                marginLeft: "20px",
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Toot;
