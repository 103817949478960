import React from "react";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import "../App.css";
import { items } from "../data";

const Shop = () => {
  return (
    <div className="App">
      <div className="Shop__container">
        {items
          .slice(0)
          .reverse()
          .map((item) => (
            <a
              href={item.url}
              key={item._uid}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="Shop__div">
                <LazyLoad height={200}>
                  <Image
                    alt="shop item poster"
                    className="Shop__image"
                    src={item.image}
                  />
                </LazyLoad>
                <h3 className="Shop__title">{item.name}</h3>
                <p className="Shop__price">${item.price}</p>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default Shop;
