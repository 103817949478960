import React from "react";
import "../App.css";
import emailjs from "emailjs-com";
import Reaptcha from "reaptcha";
import Swal from "sweetalert2";

const SERVICE_ID = process.env.REACT_APP_EJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EJS_USER_ID;

const Contact = () => {
  const [verified, setVerified] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const onVerify = () => {
    setVerified(true);
  };

  const onExpire = () => {
    Swal.fire({
      icon: "warning",
      iconColor: "orange",
      background: "#eee",
      title: "Captcha has expired!",
      text:
        "Please verify that you're not a robot by completing the captcha again.",
      //showConfirmButton: false,
      customClass: {
        title: "popup",
        text: "popup-text",
        confirmButton: "popup-btn",
      },
    });
  };

  const onError = () => {
    Swal.fire({
      icon: "error",
      iconColor: "lightcoral",
      background: "#eee",
      title: "Captcha error!",
      text: "Something went wrong. Please reload the page and try again.",
      //showConfirmButton: false,
      customClass: {
        title: "popup",
        text: "popup-text",
        confirmButton: "popup-btn",
      },
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          iconColor: "lightcoral",
          background: "#eee",
          title: "Ooops, something went wrong",
          text: error.text,
          showConfirmButton: false,
          customClass: {
            title: "popup",
          },
        });
      }
    );
    e.target.reset();
    setSent(true);
  };

  return (
    <div className="App">
      <div className="contact">
        <p className="contact--text">
          contact{" "}
          <a href="mailto:ashkelam@gmail.com" className="contact--email">
            ashkelam@gmail.com
          </a>{" "}
          for inquires, commissions, collaborations, projects and print sales,
          or send a message below:
        </p>
        <p
          className={
            sent === true ? "contact--message__sent" : "contact--message"
          }
        >
          Message Sent!
        </p>
        <form
          onSubmit={handleOnSubmit}
          className={sent === true ? "contact--form__sent" : "contact--form"}
        >
          <div className="contact--form__input">
            <input type="text" placeholder="Name" name="from_name" required />
          </div>
          <div className="contact--form__input">
            <input
              type="email"
              placeholder="Email"
              name="from_email"
              required
            />
          </div>
          <div className="contact--form__input">
            <textarea placeholder="Message" name="message" required />
          </div>
          <Reaptcha
            sitekey={process.env.REACT_APP_GC_SITE_KEY}
            onVerify={onVerify}
            onExpire={onExpire}
            onError={onError}
          />
          <div className="contact--form__submit">
            <button type="submit" id="#formbtn" disabled={!verified}>
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
