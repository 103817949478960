import React from "react";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
//import { PuffLoader } from "react-spinners";
import "../App.css";
import { projects } from "../data";

const Home = () => {
  return (
    <div className="App">
      <div className="Home__container">
        <div className="Home__div">
          <LazyLoad height={200} once>
            <Image
              alt="homepage poster"
              className="Home__image"
              src={projects[3].images[81]}
            />
          </LazyLoad>
        </div>
        <div className="Home__div">
          <LazyLoad height={200} once>
            <Image
              alt="homepage poster"
              className="Home__image"
              src={projects[3].images[29]}
            />
          </LazyLoad>
        </div>
        <div className="Home__div">
          <LazyLoad height={200} once>
            <Image
              alt="homepage poster"
              className="Home__image"
              src={projects[3].images[30]}
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Home;
