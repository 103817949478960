import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import { v4 as uuidv4 } from "uuid";
import "../../App.css";
import { projects } from "../../data";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiCornerUpLeft } from "react-icons/fi";
import { FiCornerRightUp } from "react-icons/fi";
import { CgMenuGridR } from "react-icons/cg";

const Ilynl = () => {
  let images = projects[0].images;
  const length = images.length;

  const [overlay, setOverlay] = useState("overlay__off");
  const [view, setView] = useState(true);
  const [slider, setSlider] = useState(true);
  const [current, setCurrent] = useState(0);

  const switchView = () => {
    setView(!view);
    setSlider(!slider);
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  if (length <= 0) {
    return null;
  }

  return (
    <div>
      <div className="back-btn">
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <FiCornerUpLeft
            style={{
              color: "#4a865f",
              width: "20px",
              height: "auto",
              marginTop: "4px",
            }}
          />
        </Link>
      </div>
      <div className="WorkApp">
        <div
          className={overlay}
          onClick={() => {
            setOverlay("overlay__off");
            document.documentElement.style.overflow = "auto";
            document.body.scroll = "yes";
          }}
        >
          <p className="overlay__text">
            Death; the end of the life of a person or organism. I experienced it
            for the first time in 2013 with the loss of my grandfather. I was
            only twelve years old and at that age, could not comprehend nor
            understand the repercussions or effects it was having on my
            grandmother and my mother especially. It was only as the years
            progressed and I grew older that I became aware of the apparent
            changes in my family dynamic; traditions had been modified,
            religious beliefs had altered. What was most notable was neither of
            these, but rather my grandmother’s house; what it had become, what
            it had always been. Clothes from wardrobes had been removed. Trees
            had been cut down. Garden beds had stopped flourishing. Yet, photo
            and memento filled drawers still remained. As did tools hanging in
            the workshop under the house. So too the religious images above the
            bedhead. Whilst some tangible objects and spaces had been discarded,
            replaced and forgotten, others had not been disturbed or touched in
            the slightest. So, if he were to walk in again, regardless of what
            has been amended or continues to exist, it would almost be like he
            never left.
          </p>
          <p className="overlay__text">
            This work is dedicated to Josip Siljac.
          </p>
        </div>
        {view ? (
          <div className="Work__container--carousel">
            {images.map((img, index) => (
              <div
                key={uuidv4()}
                className={index === current ? "slide active" : "slide"}
              >
                {index === current && (
                  <Image
                    alt="Thumbnail"
                    className="Work__image--carousel"
                    src={img}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="Work__container--thumbnail">
            {images.map((img, index) => (
              <LazyLoad
                height={200}
                debounce={false}
                throttle={200}
                offsetVertical={200}
                key={uuidv4()}
              >
                <div
                  className={index === current ? "thumb active" : "thumb"}
                  onClick={() => {
                    setCurrent(index);
                    switchView();
                  }}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifySelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    alt="Thumbnail"
                    className="Work__image--thumbnail content-image"
                    src={img}
                  />
                </div>
              </LazyLoad>
            ))}
          </div>
        )}
      </div>
      <div className="Work__footer--carousel">
        <ul className="Work__info">
          <li className="Work__item btn-info">
            <button
              className="overlay__btn"
              onClick={() => {
                setOverlay("overlay__on");
                document.documentElement.style.overflow = "hidden";
                document.body.scroll = "no";
              }}
            >
              info
            </button>
          </li>
        </ul>
        {slider ? (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-left"
              onClick={prevSlide}
            >
              <FiChevronLeft
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
            <li className="Work__item Work__item--num">
              {current + 1} / {length}
            </li>
            <li className="Work__item Work__item--toggle" onClick={nextSlide}>
              <FiChevronRight
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        ) : (
          <ul className="Work__slider">
            <li
              className="Work__item Work__item--toggle btn-up"
              onClick={scrollToTop}
            >
              <FiCornerRightUp
                style={{
                  color: "#4a865f",
                  width: "24px",
                  height: "auto",
                  marginTop: "4px",
                }}
              />
            </li>
          </ul>
        )}
        <ul className="Work__view">
          <li className="Work__item btn-view">
            <CgMenuGridR
              onClick={switchView}
              style={{
                color: "#4a865f",
                width: "24px",
                height: "auto",
                marginTop: "4px",
                marginLeft: "20px",
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Ilynl;
