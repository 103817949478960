import React from "react";
import "../App.css";
import cv from "../assets/AshleyKelamCV.pdf";

const About = () => {
  return (
    <div className="App About">
      <p className="about__text">
        Ashley Kelam (born 2000, Melbourne, Australia) is an emerging documentary photographer and visual
        artist living and working in Melbourne, Victoria. Her
        practice predominantly investigates the suburban landscape, challenging
        the notion of mundanity and homogeneity by exploring the beauty and
        humour prevalent in those environments.
      </p>
      <p className="about__text">
        Primarily photo-based, Ashley’s work also involves ephemera, including
        found and collected objects, to examine her own
        fond experience of growing up and living in the suburbs. This long-term
        focus on Suburbia has resulted in two recent photobook publications,
        <span className="about__text--italic"> Moments from Suburbia</span>{" "}
        (2020) and <span className="about__text--italic"> skew-whiff</span>{" "}
        (2021).
      </p>
      <p className="about__text">
        Alongside completing a Bachelor of Photography degree (with a major in
        Photojournalism) from Photography Studies College (Melbourne), Ashley’s
        work was featured in a group exhibition,{" "}
        <span className="about__text--italic">First Light</span>, at the Centre
        for Contemporary Photography in Fitzroy in November 2021.
      </p>
      <div className="about__social">
        <div className="about__social--link">
          <a target="_blank" rel="noopener noreferrer" href={cv}>
            CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
