import ilynlgif from "./assets/images/ilynl.gif";
import tootgif from "./assets/images/toot.gif";
import momgif from "./assets/images/mom.gif";
import skewgif from "./assets/images/skew.gif";
// shop items
import item1 from "./assets/images/item1.jpeg";
import item2 from "./assets/images/item2.jpeg";

function importAll(r) {
  return r.keys().map(r);
}

// importing the images for each projects folder
//require.context("../assets/images/skew-whiff", false, /\.(jpg)$/)
const itslikeyouneverleft = importAll(
  require.context("./assets/images/itslikeyouneverleft/")
);
const tootgarook = importAll(require.context("./assets/images/tootgarook/"));
const momentsfromsuburbia = importAll(
  require.context("./assets/images/momentsfromsuburbia/")
);
const skewwhiff = importAll(require.context("./assets/images/skew-whiff/"));

// giving the project an ID, image folder, and name
// oldest to newest
let projects = [
  {
    _uid: "58596b7d",
    date: " 2019-1",
    name: "It's Like You Never Left",
    images: itslikeyouneverleft,
    gif: ilynlgif,
  },
  {
    _uid: "89a9e55a",
    date: " 2020-1",
    name: "Tootgarook: A Place to See",
    images: tootgarook,
    gif: tootgif,
  },
  {
    _uid: "fdfe9b18",
    date: " 2020-2",
    name: "Moments from Suburbia",
    images: momentsfromsuburbia,
    gif: momgif,
  },
  {
    _uid: "540ce579",
    date: " 2021-1",
    name: "skew-whiff",
    images: skewwhiff,
    gif: skewgif,
  },
];

let items = [
  {
    _uid: "a54394d8",
    name: "Cape Schanck Landscape Photographic Print 1",
    price: "60.00",
    url:
      "https://www.etsy.com/au/listing/1106084053/cape-schanck-landscape-photographic?ref=shop_home_active_2",
    image: item1,
  },
  {
    _uid: "595cca3e",
    name: "Cape Schanck Landscape Photographic Print 2",
    price: "60.00",
    url:
      "https://www.etsy.com/au/listing/1097218142/cape-schanck-landscape-photographic?ref=shop_home_active_1",
    image: item2,
  },
];

export { projects, items };
