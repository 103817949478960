import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Image from "react-graceful-image";
import "../App.css";
import { projects } from "../data";

const Projects = () => {
  const [current, setCurrent] = useState(0);
  const [gif, setGif] = useState("Project__image--gif-off");

  return (
    <div className="App">
      <div className="Projects__container">
        {projects
          .slice(0)
          .reverse()
          .map((project, index) => (
            <Link
              to={`/projects/${project._uid}`}
              style={{ textDecoration: "none" }}
              key={project._uid}
            >
              <div
                className="Project__container"
                onMouseEnter={() => {
                  setCurrent(index);
                  setGif("Project__image--gif-on");
                }}
                onMouseLeave={() => {
                  setGif("Project__image--gif-off");
                }}
              >
                <LazyLoad height={200}>
                  <Image
                    alt="project poster"
                    className="Project__image"
                    src={project.images[0]}
                  />
                  <Image
                    alt="gif poster"
                    className={
                      current === index ? gif : "Project__image--gif-off"
                    }
                    src={project.gif}
                  />
                </LazyLoad>
                <h3 className="Project__title">
                  {project.name}
                  <br />
                  <span>{project.date.slice(0, -2)}</span>
                </h3>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Projects;
