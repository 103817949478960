import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "./App.css";
// global componenets
import Nav from "./components/Nav";
import Footer from "./components/Footer";
// pages
import Home from "./components/Home";
import Projects from "./components/Projects";
import Shop from "./components/Shop";
import About from "./components/About";
import Contact from "./components/Contact";
// project pages
import Skew from "./components/projects/Skew";
import Toot from "./components/projects/Toot";
import Ilynl from "./components/projects/Ilynl";
import Moments from "./components/projects/Moments";
//require("dotenv").config();
//import dotenv from "dotenv";
//dotenv.config({ path: "/server/.env" });

const NoMatch = () => {
  return (
    <div className="noMatchPage">
      <h3>404 - Page Not Found.</h3>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Nav />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/projects/58596b7d" component={Ilynl} />
        <Route path="/projects/89a9e55a" component={Toot} />
        <Route path="/projects/fdfe9b18" component={Moments} />
        <Route path="/projects/540ce579" component={Skew} />
        <Route path="/shop" exact component={Shop} />
        <Route component={NoMatch} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
